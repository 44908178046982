































































































import {
  computed,
  defineComponent,
  reactive,
  ref,
  useContext,
  useRouter,
  watch
} from '@nuxtjs/composition-api';
import { useUiState, useUser, useConfig } from '~/composables';
import { SfInput } from '@storefront-ui/vue';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { emailRegex } from "~/diptyqueTheme/helpers/regexes";

extend('email_ex', {
  message: 'Invalid email address',
  validate(value) {
    return {
      valid: emailRegex.test(value)
    };
  }
});

export default defineComponent({
  name: 'Login',
  components: {
    VaimoButton: () => import('atoms/VaimoButton.vue'),
    VaimoSocialLogin: () => import('organisms/VaimoSocialLogin.vue'),
    VaimoPasswordInput: () => import('organisms/VaimoPasswordInput.vue'),
    VaimoCheckbox: () => import('molecules/VaimoCheckbox.vue'),
    ValidationProvider,
    ValidationObserver,
    SfInput
  },
  setup() {
    const { config } = useConfig();
    const { toggleCartSidebar } = useUiState();
    const { loading, login, isAuthenticated } = useUser();
    const router = useRouter();
    const { app } = useContext();
    const form = reactive({
      email: null,
      password: null
    });
    const isRememberMeChecked = ref(false);
    const isSocialLoginEnabled = computed(() => config.value?.sociallogin_general_enabled);
    const selectRememberMe = () => {
      isRememberMeChecked.value = !isRememberMeChecked.value;
    };
    const proceedToRegister = () => {
      /*
      Attention TEMPORARY solution to open register form on button click
      Todo: Add new action type and computed in VaimoMiniCart to open Register, ForgotPassword, etc.
      * */
      toggleCartSidebar('account');
      setTimeout(() => {
        const registerButton: HTMLElement = document.querySelector(
          '.account-create > div > button'
        );
        registerButton.click();
      }, 1000);
    };

    const proceedToForgotPass = () => {
      /*
     Attention TEMPORARY solution to open forgot-password-form on button click
     Todo: Add new action type and computed in VaimoMiniCart to open Register, ForgotPassword, etc.
     * */
      toggleCartSidebar('account');
      setTimeout(() => {
        const forgotPasswordButton: HTMLElement = document.querySelector(
          '.vaimo-account-login-form > span > .forgot-password'
        );
        forgotPasswordButton.click();
      }, 1000);
    };
    const onLogin = async (invalid) => {
      if (invalid) {
        return false;
      }
      await login({
        user: {
          email: form.email,
          password: form.password
        }
      });
    };

    watch(
      () => isAuthenticated.value,
      (newValue) => {
        if (newValue) {
          router.push(app.localePath('/'));
        }
      }
    );

    return {
      form,
      isRememberMeChecked,
      proceedToRegister,
      proceedToForgotPass,
      selectRememberMe,
      onLogin,
      loading,
      toggleCartSidebar,
      isSocialLoginEnabled
    };
  }
});
